<template>
  <div class="promoter">
    <div class="promoter-box">
      <el-tabs tab-position="left" @tab-click="get_activity_goods(1)">
        <el-tab-pane label="推广设置">
          <el-alert
            title="配置完成后，可根据实际需求修改配置"
            type="info"
            :closable="false"
          >
          </el-alert>
          <div class="promoter-box-nexts">
            <el-steps :active="active" finish-status="success" align-center>
              <el-step title="设置佣金比例"></el-step>
              <el-step title="选择参与商品"></el-step>
              <el-step title="选择参与门店"></el-step>
            </el-steps>
          </div>

          <div class="promoter-box-cont" v-if="active == 1">
            <div>
              <el-button plain>推广员A</el-button>
              <el-button type="text">邀 请---></el-button>
              <el-button plain>推广员B</el-button>
              <el-button type="text">推 广---></el-button>
              <el-button plain>顾客成功购买</el-button>
            </div>

            <div style="margin:20px auto;width:45%;">
              <div class="promoter-box-cont-item">
                <p class="promoter-box-cont-span" style="width:80px;">
                  推广员A：
                </p>
                <el-input-number
                  v-model="from.father_second"
                  :precision="2"
                  :step="0.1"
                  :min="0.0"
                  :max="100"
                ></el-input-number>
                <p class="promoter-box-cont-span">%</p>
              </div>
              <div class="promoter-box-cont-item">
                <p class="promoter-box-cont-span" style="width:80px;">
                  推广员B：
                </p>
                <el-input-number
                  v-model="from.father_first"
                  :precision="2"
                  :step="0.1"
                  :min="0.0"
                  :max="100"
                ></el-input-number>
                <p class="promoter-box-cont-span">%</p>
              </div>
            </div>
          </div>

          <div class="promoter-box-cont" v-if="active == 2">
            <div class="promoter-box-cont-btn">
              <!-- <div class="promoter-box-cont-btn-item" @click="btns(0)">
                <div>
                  <p>全店商品</p>
                  <p>新上架商品会自动加入推广</p>
                </div>
              </div> -->
              <div class="promoter-box-cont-btn-item" @click="btns(1)">
                <div>
                  <p>选择商品</p>
                  <p></p>
                </div>
              </div>
              <div class="promoter-box-cont-btn-item" @click="btns(2)">
                <div>
                  <p>选择服务</p>
                  <p></p>
                </div>
              </div>
              <div class="promoter-box-cont-btn-item" @click="btns(3)">
                <div>
                  <p>选择卡项</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="promoter-box-cont" v-if="active == 3">
            <el-table
              ref="multipleTable"
              :stripe="$table('stripe')"
              :data="shopData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="门店名" width="120">
                <template slot-scope="scope">{{ scope.row.name }}</template>
              </el-table-column>
              <el-table-column prop="phone" label="门店电话" width="120">
              </el-table-column>
              <el-table-column
                prop="address"
                label="门店地址"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>

          <el-button
            type="primary"
            v-if="active > 1"
            style="margin-top: 12px;"
            @click="next(0)"
            >上一步</el-button
          >
          <el-button
            type="primary"
            v-if="active < 3"
            style="margin-top: 12px;"
            @click="next(1)"
            >下一步</el-button
          >
          <el-button
            type="danger"
            v-if="active == 3"
            style="margin-top: 12px;"
            @click="add_activity_goods"
            >保存设置</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="推广内容">
          <el-tabs tab-position="top">
            <el-tab-pane label="参与商品">
              <!-- <div class="tuiguan">
                推广模式：
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="指定商品模式推广：单独指定某些商品，全部商品推广：门店所以商品全部参与推广活动。"
                  placement="bottom-start"
                >
                  <el-switch
                    v-model="value1"
                    active-text="全部商品"
                    inactive-text="指定商品"
                  >
                  </el-switch>
                </el-tooltip>
              </div> -->
              <div style="display:flex;margin-top:20px;">
                <div
                  style="width:30%; display: flex; align-items: center;justify-content: flex-start;"
                >
                  <el-button plain @click="get_activity_goods(1)"
                    >商品</el-button
                  >
                  <el-button plain @click="get_activity_goods(2)"
                    >服务</el-button
                  >
                  <el-button plain @click="get_activity_goods(3)"
                    >卡项</el-button
                  >
                </div>
                <div
                  style="width:70%; display: flex; align-items: center;justify-content: flex-end;"
                >
                  <el-input
                    style="width:80%;"
                    placeholder="请输入内容"
                    v-model="input2"
                  >
                    <template slot="append"
                      ><i class="el-icon-search"></i
                    ></template>
                  </el-input>
                </div>
              </div>
              <div style="margin-top:20px;">
                <el-table
                  ref="multipleTable"
                  :data="GoodsList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :border="$table('border')"
                  :stripe="$table('stripe')"
                  :header-cell-style="{ background: $table('bgcolor') }"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column label="商品名">
                    <template slot-scope="scope">{{
                      scope.row.goods_info.name
                    }}</template>
                  </el-table-column>
                  <el-table-column prop="name" label="售价（元）">
                    <template slot-scope="scope">{{
                      scope.row.goods_info.price
                    }}</template>
                  </el-table-column>
                  <!-- <el-table-column prop="goods_id" label="上架门店">
                  </el-table-column> -->
                  <el-table-column prop="father_second" label="推广提成">
                  </el-table-column>
                  <el-table-column prop="father_first" label="邀请奖励提成">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="操作"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <div class="btn-box">
                        <el-button size="mini" @click="editGoods(scope)"
                          >编辑</el-button
                        >
                        <el-button
                          size="mini"
                          type="danger"
                          @click="del_activity_goods(scope.row, 0)"
                          >删除</el-button
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div
                  style="margin:20px 0; display: flex; align-items: center;justify-content: flex-start;"
                >
                  <el-button type="warning" @click="dialogVisible1 = true"
                    >批量修改提成</el-button
                  >
                  <el-button
                    type="danger"
                    @click="del_activity_goods(goodsData, 1)"
                    >批量删除</el-button
                  >
                </div>
                <div>
                  <el-pagination
                    v-if="$pageStatus(total)[0]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="$pageStatus(total)[1]"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="参与门店">暂无开放，请稍后重试...</el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="收益订单">
          <div
            style="margin:20px 0; display: flex; align-items: center;justify-content: flex-end;"
          >
            <span>订单时间:</span>
            <el-date-picker
              v-model="time1"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <span> 至 </span>
            <el-date-picker
              v-model="time2"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>

            <el-input
              placeholder="请输入内容"
              v-model="input3"
              class="input-with-select"
              style="width:35%;margin-left:20px;"
            >
              <el-select
                v-model="select"
                style="width:100px;"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="全部" value="0"></el-option>
                <!-- <el-option label="餐厅名" value="1"></el-option>
                <el-option label="订单号" value="2"></el-option>
                <el-option label="用户电话" value="3"></el-option> -->
              </el-select>
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>

          <div style="margin-top:20px;">
            <div
              style="margin:20px 0; display: flex; align-items: center;justify-content: flex-start;"
            >
              <el-table
                :data="tableData"
                height="250"
                :border="$table('border')"
                :header-cell-style="{ background: $table('bgcolor') }"
                style="width: 100%"
              >
                <el-table-column
                  prop="date"
                  label="订单编号"
                  :width="$table('width')"
                >
                </el-table-column>
                <el-table-column prop="name" label="实付金额（元）" width="180">
                </el-table-column>
                <el-table-column prop="address" label="下单门店">
                </el-table-column>
                <el-table-column prop="address" label="客户"> </el-table-column>
                <el-table-column prop="address" label="推广员">
                </el-table-column>
                <el-table-column prop="address" label="类型"> </el-table-column>
                <el-table-column prop="address" label="收益"> </el-table-column>
                <el-table-column prop="address" label="结算状态">
                </el-table-column>
              </el-table>
            </div>
            <div>
              <el-pagination
                v-if="$pageStatus(total)[0]"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="$pageStatus(total)[1]"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 服务选择 -->
    <el-dialog title="选择服务" :visible.sync="dialogVisible" width="80%">
      <el-tabs tab-position="left">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in project_data"
          :key="index"
        >
          <el-table
            ref="multipleTable"
            :data="item.list"
            tooltip-effect="dark"
            style="width: 100%"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="服务编号" :width="$table('width')">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column prop="name" label="服务名称" width="120">
            </el-table-column>
            <el-table-column
              prop="price"
              label="服务价格"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="successBtn(2)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 次卡选择 -->
    <el-dialog title="选择次卡" :visible.sync="dialogVisible2" width="80%">
      <el-tabs tab-position="left">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in project_data"
          :key="index"
        >
          <el-table
            ref="multipleTable"
            :data="item.list"
            tooltip-effect="dark"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="次卡编号" :width="$table('width')">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column prop="name" label="名称次卡" width="120">
            </el-table-column>
            <el-table-column prop="name" label="服务项目" width="120">
            </el-table-column>
            <el-table-column prop="name" label="服务次数" width="120">
            </el-table-column>
            <el-table-column
              prop="price"
              label="次卡价格"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div>
        <el-pagination
          v-if="$pageStatus(total1)[0]"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="$pageStatus(total1)[1]"
          layout="total, prev, pager, next, jumper"
          :total="total1"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="successBtn(3)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商品选择 -->
    <el-dialog title="选择商品" :visible.sync="dialogVisible3" width="80%">
      <el-tabs tab-position="left">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in project_data"
          :key="index"
        >
          <el-table
            ref="multipleTable"
            :data="item.list"
            tooltip-effect="dark"
            style="width: 100%"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="商品编号" :width="$table('width')">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称"> </el-table-column>
            <el-table-column
              prop="price"
              label="商品价格"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div>
        <el-pagination
          v-if="$pageStatus(total2)[0]"
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="$pageStatus(total2)[1]"
          layout="total, prev, pager, next, jumper"
          :total="total2"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="successBtn(1)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 提成设置 -->
    <el-dialog title="设置商品提成" :visible.sync="dialogVisible1" width="80%">
      <el-table :data="tableData2" style="width: 100%">
        <!-- <el-table-column prop="date" label="提成模式">
          <template slot-scope="scope">
            <el-radio v-model="scope.row.radio" label="0"
              >默认分组提成</el-radio
            >
            <el-radio v-model="scope.row.radio" label="1">自定义提成</el-radio>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="推广提成">
          <template slot-scope="scope">
            <div class="promoter-box-cont-item">
              <el-input-number
                v-model="scope.row.value"
                :precision="2"
                :disabled="!(scope.row.radio - 0 == 1)"
                :step="0.1"
                :min="0.0"
                :max="100"
              ></el-input-number>
              <p class="promoter-box-cont-span">%</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="邀请奖励提成">
          <template slot-scope="scope">
            <div class="promoter-box-cont-item">
              <el-input-number
                v-model="scope.row.value1"
                :precision="2"
                :disabled="!(scope.row.radio - 0 == 1)"
                :step="0.1"
                :min="0.0"
                :max="100"
              ></el-input-number>
              <p class="promoter-box-cont-span">%</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="edit_activity_goods(goodsData)"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 推广商品编辑 -->
    <el-dialog title="编辑推广商品" :visible.sync="dialogVisible4" width="80%">
      <div>
        <el-form
          label-position="right"
          label-width="80px"
          :model="formLabelAlign"
        >
          <el-form-item label="名称">
            <div class="flex-satre">
              <el-input
                disabled
                :value="formLabelAlign.goods_info.name"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="价格">
            <div class="flex-satre">
              <el-input-number
                disabled
                :value="formLabelAlign.goods_info.price"
                :precision="2"
                :step="0.1"
                :min="0.1"
                :max="100"
              ></el-input-number>
            </div>
          </el-form-item>
          <el-form-item label="推广员A提成">
            <div class="flex-satre">
              <el-input-number
                v-model="formLabelAlign.father_second"
                :precision="2"
                :step="0.1"
                :min="0.1"
                :max="100"
              ></el-input-number>
            </div>
          </el-form-item>
          <el-form-item label="推广员B提成">
            <div class="flex-satre">
              <el-input-number
                v-model="formLabelAlign.father_first"
                :precision="2"
                :step="0.1"
                :min="0.1"
                :max="100"
              ></el-input-number>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="edit_activity_goods(0)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      from: {
        father_first: "", //B
        father_second: "", //A
        goods: [],
      },
      inputType: "",
      shopData: sessionStorage.getItem("init")
        ? JSON.parse(sessionStorage.getItem("init")).group_shops
        : [], //门店数据
      tableData: [],
      multipleSelection: [],
      project_data: [],
      dialogVisible: false,
      value1: false,
      input2: "",
      currentPage4: 1,
      dialogVisible1: false,
      tableData2: [{ radio: "1", value: "", value1: "", list: [] }],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      GoodsList: [],
      total: 1,
      page: 1,
      getGoodsType: 1,
      time1: "",
      time2: "",
      input3: "",
      select: "",
      dialogVisible2: false,
      total1: 1,
      page1: 1,
      dialogVisible3: false,
      total2: 1,
      page2: 1,
      typeIndex: 0, //商品类型 1：商品 ，2：项目，3：次卡
      goodsData: [], //商品数据
      dialogVisible4: false,
      formLabelAlign: { goods_info: {} }, //编辑推广商品数据
    };
  },
  methods: {
    next(obj) {
      if (obj) {
        this.active++;
        this.active = this.active > 2 ? 3 : this.active;
      } else {
        this.active--;
        this.active = this.active < 1 ? 1 : this.active--;
      }
    },
    btns(obj) {
      if (obj == 1) {
        // this.$message.error("功能维护升级中，请稍后再试...");
        this.typeIndex = obj;
        this.getShop();
        return false;
      }
      if (obj == 2) {
        this.typeIndex = obj;
        this.getprojecttype();
        return false;
      }
      if (obj == 3) {
        this.typeIndex = obj;
        this.getType();
        return false;
      }
      if (!obj) {
        this.$confirm(
          "此操作将把全店商品加入推广，请谨慎处理, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          })
          .catch(() => {});
        return false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.goodsData = val;
      console.log(val);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page = val;
      this.get_activity_goods(this.getGoodsType);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.get_activity_goods(this.getGoodsType);
    },
    handleSizeChange1(val) {
      this.page1 = val;
      this.getType();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.getType();
      // console.log(`当前页: ${val}`);
    },
    handleSizeChange2(val) {
      this.page2 = val;
      this.getShop();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange2(val) {
      this.page2 = val;
      this.getShop();
      // console.log(`当前页: ${val}`);
    },
    successBtn(obj) {
      let _this = this;
      if (obj == 1) {
        this.dialogVisible3 = false;
        // console.log(this.goodsData);
        goods(this.goodsData, 1);
      }
      if (obj == 2) {
        this.dialogVisible = false;
        // console.log(this.goodsData);
        goods(this.goodsData, 2);
      }
      if (obj == 3) {
        this.dialogVisible2 = false;
        // console.log(this.goodsData);
        goods(this.goodsData, 3);
      }

      function goods(obj, type) {
        let len = _this.from.goods.length;
        let d = _this.from.goods;

        let len1 = obj.length;
        if (!len) {
          for (let l = 0; l < len1; l++) {
            d.push({ goods_type: type, goods_id: obj[l].id });
          }
          _this.goodsData = [];
          return false;
        }

        for (let l = 0; l < len1; l++) {
          for (let i = 0; i < len; i++) {
            if (obj[l].id == d[i].goods_id && d[i].goods_type - 0 == type) {
              d.splice(i, 1);
            } else {
              d.push({ goods_type: type, goods_id: obj[l].id });
            }
          }
        }
        _this.goodsData = [];

        let arr_sort = [];
        d = d.reduce(function(item, next) {
          arr_sort[next.goods_id]
            ? ""
            : (arr_sort[next.goods_id] = true && item.push(next));
          return item;
        }, []);

        // console.log(d);
        _this.from.goods = d;
      }
    },
    add_activity_goods() {
      /* if (!(this.from.father_first - 0)) {
        this.$message({
          message: "请输入推广员B佣金比例",
          type: "warning",
        });
        return false;
      }
      if (!(this.from.father_second - 0)) {
        this.$message({
          message: "请输入推广员A佣金比例",
          type: "warning",
        });
        return false;
      } */

      if (!this.from.goods.length) {
        this.$message({
          message: "请选择推广商品",
          type: "warning",
        });
        return false;
      }

      this.from.father_first = this.from.father_first + "";
      this.from.father_second = this.from.father_second + "";
      this.from.goods =
        typeof this.from.goods === "string"
          ? this.from.goods
          : JSON.stringify(this.from.goods);

      this.$confirm("此操作将商品添加推广, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/activity_goods/add",
            data: this.from,
          }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          });
        })
        .catch(() => {});
    },
    /* 服务 */
    getprojecttype() {
      this.project_data = [];
      this.$axios({
        href: "/api/app/meiye/projecttype/store",
      }).then((res) => {
        let len = res.data.length;
        for (let i = 0; i < len; i++) {
          res.data[i].list = [];
          this.project_data.push(res.data[i]);
        }
        this.$axios({
          href: "/api/app/meiye/project/store",
        }).then((res) => {
          let len = res.data.length;
          let len1 = this.project_data.length;
          for (let i = 0; i < len1; i++) {
            for (let l = 0; l < len; l++) {
              if (this.project_data[i].id == res.data[l].category_id) {
                this.project_data[i].list.push(res.data[l]);
              }
            }
          }
          this.dialogVisible = true;
          console.log(this.project_data);
        });
      });
    },
    /* 卡项（次卡） */
    getType() {
      this.project_data = [];
      this.$axios({
        href: "/api/app/meiye/projecttype/store",
      }).then((res) => {
        let len = res.data.length;
        for (let i = 0; i < len; i++) {
          res.data[i].list = [];
        }
        this.project_data = res.data;
        this.$axios({
          href: "/api/app/meiye/project_card/store",
          data: { page: this.page1 },
        }).then((res) => {
          let len = this.project_data.length;
          let len1 = res.data.data.items.length;
          let data = res.data.data.items;
          this.total1 = res.data.data.total;
          for (let i = 0; i < len; i++) {
            for (let l = 0; l < len1; l++) {
              if (data[l].project.category_id == this.project_data[i].id) {
                this.project_data[i].list.push(data[l]);
              }
            }
          }
          this.dialogVisible2 = true;
          // console.log(this.tab);
        });
      });
    },
    /* 商品 */
    getShop() {
      this.project_data = [];
      this.$axios({
        href: "/api/app/meiye/producttype/store",
      }).then((res) => {
        let len = res.data.length;
        for (let i = 0; i < len; i++) {
          res.data[i].list = [];
          this.project_data.push(res.data[i]);
        }
        this.$axios({
          href: "/api/app/meiye/product/store",
          data: { status: 1, page: this.page2 },
        }).then((res) => {
          let len = res.data.items.length;
          let len1 = this.project_data.length;
          this.total2 = res.data.total;
          for (let i = 0; i < len1; i++) {
            for (let l = 0; l < len; l++) {
              if (this.project_data[i].id == res.data.items[l].category_id) {
                this.project_data[i].list.push(res.data.items[l]);
              }
            }
          }
          this.dialogVisible3 = true;
          console.log(this.project_data);
        });
      });
    },
    /* 获取推广商品列表 */
    get_activity_goods(obj) {
      let goods_type = obj || 1;

      if (this.getGoodsType != obj) {
        this.page = 1;
      }
      this.getGoodsType = goods_type;

      this.$axios({
        href: "/api/app/meiye/activity_goods/store",
        data: { goods_type: goods_type, page: this.page },
      }).then((res) => {
        console.log('get_activity_goods_res', res);
        this.total = res.data.data.total;
        this.GoodsList = res.data.data.items;
      });
    },
    /* 显示编辑推广商品 */
    editGoods(obj) {
      console.log(obj);
      this.formLabelAlign = obj.row;
      this.dialogVisible4 = true;
    },
    /* 编辑推广商品 */
    edit_activity_goods(obj) {
      console.log(this.tableData2);
      let d = {
        ids: [],
        father_first: this.formLabelAlign.father_first,
        father_second: this.formLabelAlign.father_second,
        status: this.formLabelAlign.status,
      };
      if (!obj) {
        d.ids.push(this.formLabelAlign.id);
      } else {
        d.father_first = this.tableData2[0].value1;
        d.father_second = this.tableData2[0].value;

        let len = obj.length;
        for (let i = 0; i < len; i++) {
          d.ids.push(obj[i].id);
        }
        // this.dialogVisible1 = false
      }

      if (d.father_first + d.father_second > 100) {
        this.$message({
          message: "推广提成之和不能超过100",
          type: "warning",
        });
        return false;
      }

      d.ids = JSON.stringify(d.ids);

      this.$confirm("此操作将修改商品推广提成, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/activity_goods/edit",
            data: d,
          }).then(() => {
            this.dialogVisible4 = false;
            this.dialogVisible1 = false;
            this.get_activity_goods(this.getGoodsType);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          });
        })
        .catch(() => {});

      // console.log(d,this.formLabelAlign.id)
    },
    del_activity_goods(obj, type) {
      console.log(obj);

      let ids = [];

      if (!type) {
        ids = [obj.id];
      } else {
        let len = obj.length;
        for (let i = 0; i < len; i++) {
          ids.push(obj[i].id);
        }
      }

      this.$confirm("此操作将删除商品推广提成, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/activity_goods/del",
            data: { ids: JSON.stringify(ids) },
          }).then(() => {
            this.get_activity_goods(this.getGoodsType);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.promoter {
  width: 100%;
  min-height: 100%;
  background: #fff;
  &-box {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    &-nexts {
      // width: 90%;
      height: 50px;
      margin: 10px auto;
    }
    &-cont {
      width: 100%;
      height: auto;
      margin: 50px 0 30px 0;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
      }
      &-span {
        display: inline-block;
        min-width: 40px;
        max-width: auto;
        padding: 5px;
        box-sizing: border-box;
        height: 40px;
        border-radius: 3px;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f7fa;
        color: #606266;
        cursor: pointer;
        font-size: 13px;
      }
      &-btn {
        width: 100%;
        height: 200px;
        padding: 10px;
        box-sizing: border-box;
        // background: red;
        display: flex;
        align-items: center;
        justify-content: center;
        &-item:hover {
          color: #409eff;
          background: #ecf5ff;
          border: 1px solid #b3d8ff;
        }
        &-item {
          width: 30%;
          height: 100px;
          margin: 0 1%;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
          background: #fff;
          border: 1px solid #eee;
          transition: all 0.5s;
          p:first-child {
            font-size: 20px;
            font-weight: 800;
          }
          p:last-child {
            font-size: 13px;
            color: rgb(134, 134, 134);
          }
        }
      }
    }
  }
}
.tuiguan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
}
.flex-satre {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>

<style>
.el-input-group__append {
  cursor: pointer;
}
</style>

<style moudle>
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
</style>
